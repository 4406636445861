import React, { useState } from 'react';

/**
 * React UUID
 */
import uuid from 'react-uuid';

/**
 * Material UI
 */
import { Grid, Box, FormControlLabel, Switch, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { RichTextEditor } from '../../../lib/slate/RichTextEditor';
import { ExpanableCheckbox, TextFieldWithSpeechRecognition } from '../../../common/components/controls';

/**
 * Constants
 */
const INITIAL_QUESTION_STAGE_DEFAULT = "input";

/**
 * Usages:
 * 
 * 1. In order for it to work, various QuestionEditor must be passed as immediate children of this SectionEditor,
 *    as section processing methods would be passed in as props
 * 2. section needs to be passed as prop to the element in order for it to work
 * 3. isEditing would be derived as if any questions in non-undefined stage
 */
export function SectionEditor(props) {
    const classes = makeStyles(theme => ({
        root: {
        },
        buttonGrid: {
            textAlign: "right"
        },
        switchLabel: {
            fontSize: "0.5rem"
        },
        questionEditor: {
            marginTop: theme.spacing(1)
        },
        boldTextField: {
            fontWeight: 800
        }
    }))();
    const { section, sectionIndex, updateSection, languageCode } = props;
    const initialQuestionState = props.initialQuestionState || INITIAL_QUESTION_STAGE_DEFAULT;
    const [hasRichText, setRichText] = useState(Boolean(section.richText));
    const toggleRichText = () => {
        if (Boolean(hasRichText)) {
            setRichText(false);
            updateAttribute("richText", null);
        } else {
            setRichText(true);
        }
    }
    /**
     * Set of generics method for manipulating this section and its parent paper
     */
    const updateThisSection = (sectionIndex, updatedSection) => {
        updateSection(sectionIndex, Object.assign({},
            updatedSection, {
            // If any of the question stage is not undefined, the section will be regarded as in edit
            isEditing: section.questions.filter(q => q.stage !== undefined).length > 0
        }));
    }
    const addQuestion = (questionIndex) => {
        var updatedSection = Object.assign({}, section);
        updatedSection.questions.splice(questionIndex + 1, 0,
            Object.assign({}, { id: uuid(), stage: initialQuestionState }));
        updateThisSection(sectionIndex, updatedSection);
    }
    const removeQuestion = (questionIndex) => {
        var updatedSection = Object.assign({}, section);
        updatedSection.questions.splice(questionIndex, 1);
        updateThisSection(sectionIndex, updatedSection);
    }
    const updateQuestion = (questionIndex, question) => {
        var updatedSection = Object.assign({}, section);
        updatedSection.questions[questionIndex] = question;
        updateThisSection(sectionIndex, updatedSection);
    }
    const updateAttribute = (attributeKey, attributeValue) => {
        var updatedSection = Object.assign({}, section);
        updatedSection[attributeKey] = attributeValue;
        updateThisSection(sectionIndex, updatedSection);
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <TextFieldWithSpeechRecognition
                    label="Section title"
                    languageCode={languageCode}
                    inputProps={{ className: classes.boldTextField }}
                    InputLabelProps={{ shrink: true }}
                    value={section.title}
                    fullWidth onChange={e => updateAttribute("title", e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <TextFieldWithSpeechRecognition
                    label="Instruction"
                    languageCode={languageCode}
                    InputLabelProps={{ shrink: true }}
                    value={section.instruction}
                    fullWidth onChange={e => updateAttribute("instruction", e.target.value)} />
            </Grid>
            {/* Rich Text */}
            <Grid item xs={12}>
                <Box textAlign="right">
                    <ExpanableCheckbox
                        checked={hasRichText}
                        onChange={toggleRichText}
                        label="Richtext Introduction" />
                </Box>
                {(Boolean(hasRichText)) &&
                    <RichTextEditor variant="outlined"
                        value={section.richText}
                        setValue={value => updateAttribute("richText", value)} />}
            </Grid>
            {/* End: Rich Text */}
            {props.children}
            {(props.questions || []).map(child =>
                React.cloneElement(child, Object.assign({},
                    {
                        addQuestion: addQuestion,
                        removeQuestion: removeQuestion,
                        updateQuestion: updateQuestion
                    },
                    child.props,
                ))
            )}
            <Box mb={20} />
        </Grid>
    );
}
