import React, { useState, useEffect } from 'react';

/**
 * React Router
 */
import { useParams } from 'react-router-dom';

/**
 * React UUID
 */
import uuid from 'react-uuid';

/**
 * Material UI
 */
import { Box, Container, Grid, Button, Typography } from '@material-ui/core';
import { AppBar, Tabs, Tab, IconButton, Icon, Menu, MenuItem, Tooltip, Badge } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Firebase
 */
import { firestore, getFirestoreTimestamp } from '../../../common/serviecs/firebase';

/**
 * Application
 */
import { LoadingMask } from '../../../common/components/controls/LoadingMask';
import { MessageDialog } from '../../../common/components/controls/MessageDialog';
import { FillInBlankSectionEditor } from '../section-editors/FillInBlankSectionEditor';
import { MultipleChoiceSectionEditor } from '../section-editors/MultipleChoiceSectionEditor';
import { SentenceReorderingSectionEditor } from '../section-editors/SentenceReorderingSectionEditor';
import { QuestionAnswerSectionEditor } from '../section-editors/QuestionAnswerSectionEditor';
import { TrueOrFalseSectionEditor } from '../section-editors/TrueOrFalseSectionEditor';
import { ArithmeticOperationSectionEditor } from '../section-editors/ArithmeticOperationSectionEditor';
import { DictationSectionEditor } from '../section-editors/DictationSectionEditor';
import { UserSelectionMenu } from '../../../common/components/controls/UserSelection';
import { notifyUser } from '../../../common/components/controls/NotificationMenu';
import { MatchingSectionEditor } from '../section-editors/MatchingSectionEditor';
import { AssignmentDialog } from './AssignmentDialog';
import { GenericSectionConfigurationEditor } from '../section-editors/GenericSectionConfigurationEditor';
import { PaperAttributes } from './PaperAttributes';
import { AiGeneratedFillInBlankSectionEditor } from '../section-editors/AiGeneratedFillInBlankSectionEditor';

/**
 * Constants
 */
export const SECTION_EDITORS = {
    "ai-generated-fill-in-blank": {
        key: "ai-generated-fill-in-blank",
        label: "Fill in Blank (AI Generated)",
        component: AiGeneratedFillInBlankSectionEditor,
        optionsSelection: GenericSectionConfigurationEditor
    },
    "fill-in-blank": {
        key: "fill-in-blank",
        label: "Fill in Blank",
        component: FillInBlankSectionEditor,
        optionsSelection: GenericSectionConfigurationEditor
    },
    "multiple-choice": {
        key: "multiple-choice",
        label: "Multiple Choice",
        component: MultipleChoiceSectionEditor
    },
    "sentence-reordering": {
        key: "sentence-reordering",
        label: "Sentence Reordering",
        component: SentenceReorderingSectionEditor
    },
    "question-answer": {
        key: "question-answer",
        label: "Question & Answer",
        component: QuestionAnswerSectionEditor
    },
    "true-or-false": {
        key: "true-or-false",
        label: "True or False",
        component: TrueOrFalseSectionEditor
    },
    "arithmetic-operation": {
        key: "arithmetic-operation",
        label: "Arithmetic Operation",
        component: ArithmeticOperationSectionEditor
    },
    "matching": {
        key: "matching",
        label: "Matching",
        component: MatchingSectionEditor
    },
    "dictation": {
        key: "dictation",
        label: "Dictation",
        component: DictationSectionEditor
    },
    "times-table": {
        key: "times-table",
        label: "Times Table",
        component: null
    },
    "free-style-section": {
        key: "free-style-section",
        label: "Free Style Section",
        component: null
    },
};

const EMPTY_PAPER = {
    fullScore: 100, sections: []
};

const SECTION_KEY_PAPER_ATTRIBUTES = "paper-attributes";
const STATIC_TABS = [SECTION_KEY_PAPER_ATTRIBUTES];

export function PaperEditor(props) {
    const { goto, account, user, isDuplicateMode = false } = props;
    const [sectionIndexOnDrag, setSectionIndexOnDrag] = useState();
    const [loading, setLoading] = useState(["Loading your test paper"]);
    const [message, setMessage] = useState(null);
    const [paper, setPaper] = useState();
    const [isDirty, setDirty] = useState(false);
    const [isAssigning, setAssigning] = useState(false);
    const { id } = useParams();
    const [sectionIndexInEdit, setSectionIndexInEdit] = useState(0);
    const classes = makeStyles(theme => ({
        root: {

        },
        buttonsGrid: {
            display: "flex",
            justifyContent: "flex-end",
        },
        button: {
            fontWeight: 800
        },
        sectionsAppBar: {
            position: "fixed",
            top: "auto",
            bottom: 0
        },
        sectionTabLabel: {
            fontWeight: 800
        },
        sectionBox: {
            width: "100%"
        },
        alertBox: {
            color: theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: theme.spacing(0.5),
            borderRadius: theme.spacing(2),
            padding: theme.spacing(3),
            textAlign: "center",
        },
        alertBoxText: {
            fontWeight: 800
        }
    }))();

    useEffect(() => {
        if (Boolean(id)) {
            firestore.collection("test-papers").doc(id).get().then(paperFromStore => {
                if (Boolean(paperFromStore.data())) {
                    setPaper(() => Object.assign({}, paperFromStore.data()));
                    removeLoading("Loading your test paper");
                }
            });
        } else {
            setPaper(() =>
                Object.assign(
                    {}, EMPTY_PAPER,
                    {
                        ownerUserId: account.ownerUserId || account.id,
                        author: {
                            userId: account.id,
                            fullName: account.fullName
                        }
                    })
            );
            removeLoading("Loading your test paper");
        }
    }, [id])

    const addLoading = (loadingKey) => {
        setLoading(() => [...loading, ...[loadingKey]]);
    }

    const removeLoading = (loadingKey) => {
        setLoading(() => [...[], ...loading.filter(l => l !== loadingKey)]);
    }

    const isEditing = () => {
        return (paper && paper.sections && paper.sections.filter(s => s.isEditing).length > 0);
    }

    const savePaper = (close) => {
        addLoading("Saving your test paper");
        let currentTimestamp = getFirestoreTimestamp();
        var savePromise = (Boolean(id) && !Boolean(isDuplicateMode)) ?
            // Update existing
            firestore.collection("test-papers").doc(id).set(Object.assign(paper, { updatedAt: currentTimestamp })) :
            // Create new
            firestore.collection("test-papers").add(Object.assign(paper, { createdAt: currentTimestamp, updatedAt: currentTimestamp }));
        savePromise.then(docRef => {
            setDirty(() => false);
            removeLoading("Saving your test paper");
            if (close) {
                goto("/papers")
            } else if (!Boolean(id) || Boolean(isDuplicateMode)) {
                // Redirect back to the edit page
                goto(`/papers/${docRef.id}/edit`)
            }
        }).catch(error => {
            console.error("Error: ", error);
        });
    }
    const updatePaperAttributes = (attributeKey, attributeValue) => {
        var originalPaper = Object.assign({}, paper);
        originalPaper[attributeKey] = attributeValue;
        setDirty(() => true);
        setPaper(() => originalPaper);
    }
    const addNewSection = (sectionKey) => {
        var originalPaper = Object.assign({}, paper);
        originalPaper.sections.push({
            id: uuid(),
            type: sectionKey,
            title: SECTION_EDITORS[sectionKey].label
        });
        setDirty(() => true);
        setPaper(() => originalPaper);
        setSectionIndexInEdit(() => STATIC_TABS.length + originalPaper.sections.length - 1);
    }
    const removeSection = (sectionIndex) => {
        var originalPaper = Object.assign({}, paper);
        originalPaper.sections.splice(sectionIndex, 1);
        setDirty(() => true);
        setPaper(() => originalPaper);
        if (originalPaper.sections.length === 0) {
            setSectionIndexInEdit(() => 0);
        } else if (sectionIndex >= (originalPaper.sections.length)) {
            setSectionIndexInEdit(() => STATIC_TABS.length + originalPaper.sections.length - 1);
        }
    }
    const updateSection = (sectionIndex, section) => {
        var originalPaper = Object.assign({}, paper);
        originalPaper.sections.splice(sectionIndex, 1, section);
        setDirty(() => true);
        setPaper(() => originalPaper);
    }
    const moveSection = (sectionIndexOnDrop) => {
        if (sectionIndexOnDrag !== sectionIndexOnDrop) {
            var originalPaper = Object.assign({}, paper);
            var sectionToMove = originalPaper.sections[sectionIndexOnDrag];
            originalPaper.sections.splice(sectionIndexOnDrag, 1);
            var adjustedSectionIndex = (sectionIndexOnDrop - (sectionIndexOnDrag < sectionIndexOnDrop) ? 1 : 0);
            originalPaper.sections.splice(adjustedSectionIndex, 0, sectionToMove);
            setDirty(() => true);
            setPaper(() => originalPaper);
            setSectionIndexInEdit(() => adjustedSectionIndex + STATIC_TABS.length);
        }
    }
    const assignUser = (assignedUser) => {
        firestore.collection(`/test-papers/${id}/paper-attempts`).add({
            paperId: id,
            subject: paper.subject || null,
            title: paper.title || null,
            subtitle: paper.subtitle || null,
            school: paper.school || null,
            grade: paper.grade || null,
            fullScore: paper.fullScore,
            timeLimitInMinutes: paper.timeLimitInMinutes || null,
            completedAt: null,
            assignedAt: getFirestoreTimestamp(),
            assignedBy: {
                id: user.id,
                fullName: user.fullName,
            },
            attemptedBy: {
                ownerUserId: assignedUser.ownerUserId || assignedUser.id,
                id: assignedUser.id,
                fullName: assignedUser.fullName
            }
        }).then(async (docRef) => {
            await notifyUser(assignedUser, {
                type: "assignment",
                message: `${user.fullName} has assigned you a test: ${paper.grade} ${paper.subject} - ${paper.title}`,
                path: `/papers/${id}/paper-attempts/${docRef.id}`,
                createdAt: getFirestoreTimestamp()
            })
        });
    }

    const populateCreateForAttributes = (user) => {
        let { school, grade, taggings } = user;
        setPaper(() => Object.assign({}, paper, { school: school, grade: grade, taggings: (taggings || []) }));
    }

    return (
        <React.Fragment>
            {Boolean(loading.length > 0) ? <LoadingMask message={`${loading.join(" / ")}...`} /> : null}
            {Boolean(message) ? <MessageDialog open={true} title="Test Paper Online" message={message} closeCallback={() => setMessage(null)} /> : null}
            {Boolean(paper) && Boolean(isAssigning) &&
                <AssignmentDialog account={account} user={user} paper={Object.assign({ id: id }, paper)} handleClose={() => setAssigning(false)} />}
            {Boolean(paper) ?
                <React.Fragment>
                    <Container maxWidth="lg">
                        <Grid container spacing={3}>
                            <Grid item xs={12} className={classes.buttonsGrid}>
                                <NewSectionMenu addNewSection={addNewSection} disabled={isEditing()} />
                                {/* Remove Section Button */}
                                {(sectionIndexInEdit - STATIC_TABS.length >= 0) ?
                                    <React.Fragment>
                                        <Box display={{ xs: "block", md: "none" }}>
                                            <IconButton color="primary" className={classes.button} onClick={() => removeSection(sectionIndexInEdit - STATIC_TABS.length)}>
                                                <Icon>remove_circle</Icon>
                                            </IconButton>
                                        </Box>
                                        <Box display={{ xs: "none", md: "block" }}>
                                            <Button color="primary" className={classes.button} onClick={() => removeSection(sectionIndexInEdit - STATIC_TABS.length)}>Remove Section</Button>
                                        </Box>
                                    </React.Fragment> :
                                    null}
                                {/* End: Remove Section Button */}
                                {/* Save / Save & Close Buttons */}
                                <Tooltip title={isEditing() ? "Please complete all the sections you are editing before you can save your paper" : ""}>
                                    <span>
                                        <Box display={{ xs: "block", md: "none" }}>
                                            <IconButton color="primary" className={classes.button}
                                                onClick={() => savePaper(false)} disabled={isEditing() || !isDirty}>
                                                <Icon>save</Icon>
                                            </IconButton>
                                            <IconButton color="primary" className={classes.button}
                                                onClick={() => savePaper(true)} disabled={isEditing() || !isDirty}>
                                                <Icon>cancel</Icon>
                                            </IconButton>
                                        </Box>
                                        <Box display={{ xs: "none", md: "block" }}>
                                            <Button color="primary" className={classes.button}
                                                onClick={() => savePaper(false)} disabled={isEditing() || !isDirty}>Save</Button>
                                            <Button color="primary" className={classes.button}
                                                onClick={() => savePaper(true)} disabled={isEditing() || !isDirty}>Save & Close</Button>
                                        </Box>
                                    </span>
                                </Tooltip>
                                {/* Save / Save & Close Buttons */}
                                {/* Assign Button */}
                                {false && Boolean(id) && !isDirty &&
                                    <UserSelectionMenu buttonLabel="Assign" account={account} user={user} requirePassword={false} onSelectUser={assignUser} />}
                                {Boolean(id) && !isDirty &&
                                    <Button className={classes.button} color="primary" onClick={() => setAssigning(() => true)}>Assign</Button>}
                                {/* End: Assign Button */}

                                {/* Create For Button */}
                                {Boolean(sectionIndexInEdit === 0) &&
                                    <UserSelectionMenu
                                        account={account}
                                        user={user}
                                        onSelectUser={populateCreateForAttributes}
                                        buttonLabel="Create For"
                                        requirePassword={false}
                                        showDependentUsersOnly={false} />}
                                {/* End: Create For Button */}


                            </Grid>
                            {Boolean(isDuplicateMode) &&
                                <Grid item xs={12}>
                                    <Box className={classes.alertBox}>
                                        <Typography className={classes.alertBoxText}>Upon clicking the "Save" button, a new paper will be created instead of overwritting the original paper</Typography>
                                    </Box>
                                </Grid>}
                            {sectionIndexInEdit === 0 &&
                                <PaperAttributes paper={paper} updatePaperAttributes={updatePaperAttributes} />}
                            {paper.sections.map((section, sectionIndex) => {
                                // Properties of the dynamic section editor element
                                let elementProps = {
                                    key: section.id,
                                    sectionIndex: sectionIndex,
                                    section: section,
                                    languageCode: paper.languageCode,
                                    updateSection: updateSection,
                                    addLoading: addLoading,
                                    removeLoading: removeLoading
                                };
                                // If options selection available
                                if (Boolean(SECTION_EDITORS[section.type].optionsSelection)) {
                                    let updateSectionConfiguration = (key, value) => {
                                        let _section = Object.assign({}, section);
                                        let _configurations = Object.assign({}, _section.configurations, { [key]: value });
                                        _section.configurations = _configurations;
                                        updateSection(sectionIndex, _section);
                                    }
                                    elementProps.children =
                                        React.createElement(SECTION_EDITORS[section.type].optionsSelection, {
                                            section: section,
                                            updateSectionConfiguration: updateSectionConfiguration
                                        });
                                }
                                // Create element dynamically
                                return (
                                    <Box className={classes.sectionBox}
                                        key={section.id}
                                        hidden={sectionIndexInEdit !== sectionIndex + STATIC_TABS.length}>
                                        {React.createElement(SECTION_EDITORS[section.type].component, elementProps)}
                                    </Box>);
                            })}
                        </Grid>
                    </Container>
                    <AppBar className={classes.sectionsAppBar}>
                        <Tabs value={sectionIndexInEdit} onChange={(e, newValue) => setSectionIndexInEdit(newValue)} variant="scrollable" scrollButtons="auto">
                            <Tab label="Your Test Paper" wrapped />
                            {paper.sections.map((section, sectionIndex) =>
                                <Tab key={section.id} wrapped draggable
                                    onDragStart={() => setSectionIndexOnDrag(() => sectionIndex)}
                                    onDragEnd={() => setSectionIndexOnDrag(() => null)}
                                    onDragOver={(e) => { e.stopPropagation(); e.preventDefault() }}
                                    onDrop={(e) => moveSection(sectionIndex)}
                                    label={
                                        <Badge color="secondary" variant="dot" invisible={!section.isEditing}>
                                            <Typography variant="caption" className={classes.sectionTabLabel}>{section.title}</Typography>
                                        </Badge>} />
                            )}
                            <NewSectionMenu variant="tab" addNewSection={addNewSection} disabled={isEditing()} />
                        </Tabs>
                    </AppBar>
                </React.Fragment > : null
            }
        </React.Fragment >
    );
}

export function NewSectionMenu(props) {
    const { variant, addNewSection, disabled } = props;
    const classes = makeStyles(theme => ({
        newSectionButton: {
            color: theme.palette.primary.main,
            fontWeight: 800
        }
    }))();
    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = event => {
        setAnchorEl(event.target);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <div>
            {variant === "tab" ?
                <Tab disabled={disabled} label="New Section" onClick={handleClick} wrapped /> :
                <React.Fragment>
                    <Box display={{ xs: "none", md: "block" }}>
                        <Button disabled={disabled} className={classes.newSectionButton} onClick={handleClick}
                            endIcon={<Icon>expand_more</Icon>}>New Section</Button>
                    </Box>
                    <Box display={{ xs: "block", md: "none" }}>
                        <IconButton disabled={disabled} className={classes.newSectionButton} onClick={handleClick}>
                            <Icon>add_circle</Icon>
                        </IconButton>
                    </Box>
                </React.Fragment>}
            <Menu id="new-section-menu" keepMounted anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {Object.keys(SECTION_EDITORS).map(sectionKey =>
                    <MenuItem key={`new-section-${sectionKey}`}
                        disabled={!Boolean(SECTION_EDITORS[sectionKey].component)}
                        onClick={() => { addNewSection(sectionKey); handleClose() }}>
                        <Typography variant="subtitle2">{SECTION_EDITORS[sectionKey]["label"]}</Typography>
                    </MenuItem>
                )}
            </Menu>
        </div>
    );
}
