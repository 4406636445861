import React, { useContext, useEffect, useState } from 'react';

/**
 * React UUID
 */
import uuid from 'react-uuid';

/**
 * Material UI
 */
import { Grid, Box, TextField, Typography, Button, Icon, ButtonGroup, Tooltip, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/**
 * Application
 */
import { SectionEditor } from './SectionEditor';
import { TextFieldWithSpeechRecognition } from '../../../common/components/controls';
import { UserContext } from '../../../App';

const DEFAULT_SECTION_ATTRIBUTES = {
    type: "ai-generated-fill-in-blank",
    title: null,
    instruction: null,
    // provideOptions: false,
};
export function AiGeneratedFillInBlankSectionEditor(props) {
    const { user } = useContext(UserContext);
    const classes = makeStyles(theme => ({
        buttonGrid: {
            textAlign: "right"
        },
        switchLabel: {
            fontSize: "0.5rem"
        },
        questionEditor: {
            marginTop: theme.spacing(1)
        }
    }))();
    const { sectionIndex, updateSection, languageCode, addLoading, removeLoading } = props;
    const section = Object.assign({}, DEFAULT_SECTION_ATTRIBUTES, { questions: [{ id: uuid(), stage: "input" }] }, props.section);
    const updateThisSection = (sectionIndex, section) => {
        var originalSection = Object.assign({}, section, { isEditing: section.questions.filter(q => Boolean(q.stage)).length > 0 });
        updateSection(sectionIndex, originalSection);
    }
    const addQuestion = (questionIndex) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions.splice(questionIndex + 1, 0, Object.assign({}, { id: uuid(), stage: "input", autoFocus: true }));
        updateThisSection(sectionIndex, originalSection);
    }
    const removeQuestion = (questionIndex) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions.splice(questionIndex, 1);
        updateThisSection(sectionIndex, originalSection);
    }
    const updateQuestion = (questionIndex, question) => {
        var originalSection = Object.assign({}, section);
        originalSection.questions[questionIndex] = question;
        updateThisSection(sectionIndex, originalSection);
    }
    const updateAttribute = (attributeKey, attributeValue) => {
        var originalSection = Object.assign({}, section);
        originalSection[attributeKey] = attributeValue;
        updateThisSection(sectionIndex, originalSection);
    }

    const createSentenceBlocks = (word, sentence) => {
        let blocks = [];
        let remainingSentence = sentence;
        while (remainingSentence != null && remainingSentence != undefined && remainingSentence.length > 0) {
            let nextBlockStartIndex = remainingSentence.indexOf(word);
            if (nextBlockStartIndex < 0) {
                blocks.push({
                    type: "text",
                    value: remainingSentence
                });
                remainingSentence = null;
            } else {
                // If the match does not start the sentence
                if (nextBlockStartIndex > 0) {
                    blocks.push({
                        type: "text",
                        value: remainingSentence.substring(0, nextBlockStartIndex)
                    });
                }
                blocks.push({
                    type: "answer",
                    value: word
                });
                // Modify the remainingSentence for the next match
                remainingSentence = remainingSentence.substring(nextBlockStartIndex + word.length);
            }
        }
        return blocks;
    }

    /**
     * Generate questions with AI
     */
    const generateQuestions = async (generateMissingOnly = false) => {
        addLoading("Generating questions");
        let _section = Object.assign({}, section);
        let _questions = _section.questions || [];
        // Generate all questions
        let words = _questions.map(q => q.word);
        let sentences = await callSentenceCreationApi(words);
        _questions.forEach((question, qIndex) => {
            if (!generateMissingOnly || !Boolean(question.blocks)) {
                let sentence = sentences[question?.word];
                delete question.stage;
                question.blocks = createSentenceBlocks(question?.word, sentence);
            }
        });
        updateThisSection(sectionIndex, Object.assign({}, _section, { questions: _questions }));
        removeLoading("Generating questions");
    }

    const generateQuestion = async (questionIndex) => {
        addLoading("Generating question");
        // Generate a specific question
        let _question = Object.assign({}, section.questions[questionIndex]);
        let sentences = await callSentenceCreationApi(_question.word);
        let sentence = sentences[_question.word];
        delete _question.stage;
        updateQuestion(questionIndex, Object.assign({}, _question, { blocks: createSentenceBlocks(_question.word, sentence) }))
        removeLoading("Generating question");
    }

    /**
     * Call external API to generate sentences from words
     * 
     * @param {*} words 
     */
    const callSentenceCreationApi = async (words) => {
        let urlSearchParams = new URLSearchParams({
            words: words
        });

        let url = `${process.env.REACT_APP_AI_API}/ai/sentences/create?${urlSearchParams.toString()}`;
        // var params = { words: [word] }
        // // var params = [['lat', '35.696233'], ['long', '139.570431']]
        // url.search = new URLSearchParams(params).toString();
        let response = await fetch(url, {
            mode: "cors",
            credentials: "include",
            headers: {
                Authorization: `Bearer ${user.idToken}`
            }
        });
        let sentences = await response.json();
        return sentences;
    }

    return (
        <SectionEditor {...props}
            section={section}
            questions={(section.questions).map((question, questionIndex) =>
                <AiGeneratedFillInBlankQuestionEditor
                    key={question.id}
                    className={classes.questionEditor}
                    question={question}
                    questionIndex={questionIndex}
                    addQuestion={addQuestion}
                    removeQuestion={removeQuestion}
                    updateQuestion={updateQuestion}
                    isEditingSection={section.isEditing}
                    languageCode={languageCode}
                    regenerateQuestion={() => generateQuestion(questionIndex)}
                    completeSection={() => generateQuestions(true).then()} />)}>
            <Grid item xs={12} style={{ textAlign: "right" }}>
                {/* Children if any */}
                {props.children}
                {/* End: Children if any */}
                {/* Custom Buttons for this Section */}
                <Box mt={3}>
                    <AiGeneratedFillInBlankSectionEditorButtons section={section} generateQuestions={generateQuestions} />
                </Box>
                {/* End: Custom Buttons for this Section */}
            </Grid>
        </SectionEditor>
    );
}


/**
 * Stage as integer doesn't work.
 * TODO: find root cause
 */
const STAGES = {
    input: { name: "Input", hint: "Input the word to fill in, AI will generate the question for you", nextStage: "pending_ai_generation" },
    edit: { name: "Edit", hint: "Edit your question", nextStage: "select" },
    select: { name: "Select", hint: "Use the cursor to select the word(s) that you would like to leave blank.", previousStage: "edit" }
};
export function AiGeneratedFillInBlankQuestionEditor(props) {
    const classes = makeStyles(theme => ({
        root: {
            textAlign: "left"
        },
        questionText: {
            display: "inline",
        },
        questionAiGenerationRemark: {
            color: theme.palette.grey[500],
            fontWeight: 800,
            fontSize: "0.8rem",
            display: "inline",
        },
        answer: {
            display: "inline",
            borderBottom: "2px solid",
            borderColor: theme.palette.text.primary,
        },
        buttonGrid: {
            textAlign: "right",
        },
        button: {
            fontWeight: 600,
            minWidth: "max-content",
            height: "max-content",
            whiteSpace: "no-wrap",
        },
        questionNumber: {
            display: "inline",
            width: 30,
            textAlign: "left",
        },
        editorGrid: {
            display: "flex"
        },
        editorBox: {
            width: "100%"
        },
        selectionBox: {
            borderRadius: 4,
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
            borderWidth: 2,
            padding: theme.spacing(2)
        }
    }))();

    const { questionIndex, addQuestion, removeQuestion, updateQuestion, isEditingSection, languageCode, regenerateQuestion, completeSection } = props;
    const question = Object.assign({ id: uuid() }, props.question);
    const stage = question.stage;
    const [selectionMouseDownBlockIndex, setSelectionMouseDownBlockIndex] = useState();

    const complete = (nextQuestion = false) => {
        let originalQuestion = Object.assign({}, question);
        delete originalQuestion.stage;
        delete originalQuestion.autoFocus;
        updateQuestion(questionIndex, originalQuestion);
        if (nextQuestion) {
            addQuestion(questionIndex);
        } else {
            setTimeout(() =>
                completeSection(), 200);
        }
    }
    const setStage = (stage) => {
        if (Boolean(STAGES[stage])) {
            // setStage(stage + step);
            updateQuestion(questionIndex, Object.assign({}, question, { stage: stage }));
        } else {
            // setStage(null);
            // let originalQuestion = Object.assign({}, question);
            // delete originalQuestion.stage
            // updateQuestion(questionIndex, originalQuestion);
            complete(false);
        }
    }

    const handleSelection = (blockIndex) => {

        let originatingBlockIndex = selectionMouseDownBlockIndex;

        let startBlockIndex = Math.min(...[blockIndex, originatingBlockIndex].filter(Number.isFinite));
        let endBlockIndex = Math.max(...[blockIndex, originatingBlockIndex].filter(Number.isFinite));
        let startOffset = (blockIndex === originatingBlockIndex) ?
            Math.min(document.getSelection().anchorOffset, document.getSelection().focusOffset) :
            (blockIndex > originatingBlockIndex) ? document.getSelection().anchorOffset : document.getSelection().focusOffset
        let endOffset = (blockIndex === originatingBlockIndex) ?
            Math.max(document.getSelection().anchorOffset, document.getSelection().focusOffset) :
            (blockIndex > originatingBlockIndex) ? document.getSelection().focusOffset : document.getSelection().anchorOffset
        if (!(startBlockIndex === endBlockIndex && startOffset === endOffset)) {
            // Across multiple blocks
            let blocks = question.blocks;
            let beforeText = blocks[startBlockIndex].value.substring(0, startOffset);
            let afterText = blocks[endBlockIndex].value.substring(endOffset);
            let selectedText = document.getSelection().toString();
            // Special handling on leading and trailing space to improve usability
            var numberOfLeadingSpaces = selectedText.search(/[^\s]+/);
            var numberOfTrailingSpaces = selectedText.split("").reverse().join("").search(/[^\s]+/)
            beforeText = `${beforeText}${Array(numberOfLeadingSpaces).fill(" ").join("")}`
            afterText = `${Array(numberOfTrailingSpaces).fill(" ").join("")}${afterText}`
            selectedText = selectedText.substring(numberOfLeadingSpaces, selectedText.length - numberOfTrailingSpaces);
            blocks.splice(startBlockIndex, (endBlockIndex - startBlockIndex) + 1, { type: "text", value: beforeText }, { type: "answer", value: selectedText }, { type: "text", value: afterText });
            setSelectionMouseDownBlockIndex(null);
            updateQuestion(questionIndex, Object.assign({}, question, { blocks: blocks }));
        }
    }

    const contextMenuSelect = () => {
        let remainingTextLength = document.getSelection().toString().length;
        console.log(`Original Text Length: ${remainingTextLength}`);
        question.blocks.forEach((b, bIndex) => {
            if (remainingTextLength > 0) {
                if (bIndex == selectionMouseDownBlockIndex) {
                    remainingTextLength -= (b.value.length - document.getSelection().anchorOffset);
                } else if (bIndex > selectionMouseDownBlockIndex) {
                    remainingTextLength -= (b.value.length);
                }
                console.log(`bIndex:${bIndex} / remainingTextLength:${remainingTextLength}`)
                if (remainingTextLength <= 0) {
                    handleSelection(bIndex);
                    document.getSelection().removeAllRanges();
                }
            }
        });
    }

    var editor = null;
    switch (stage) {
        case "input":
            editor =
                <TextFieldWithSpeechRecognition fullWidth
                    inputProps={{ tabIndex: -1 }}
                    languageCode={languageCode}
                    autoFocus={question.autoFocus}
                    variant="outlined" multiline={true}
                    value={question.word}
                    onKeyDown={(e) => {
                        if (e.keyCode == 9) {
                            complete(true);
                        }
                    }}
                    onChange={e => updateQuestion(questionIndex, Object.assign({}, question, { word: e.target.value }))}
                // onChange={e => updateQuestion(questionIndex, Object.assign({}, question, { blocks: [{ type: "text", value: e.target.value }] }))} 
                />
            break;
        case "edit":
            editor =
                <TextFieldWithSpeechRecognition fullWidth
                    languageCode={languageCode}
                    autoFocus={question.autoFocus}
                    variant="outlined" multiline={true}
                    value={(question.blocks || []).map(b => b.value).join("")}
                    onChange={e => updateQuestion(questionIndex, Object.assign({}, question, { blocks: [{ type: "text", value: e.target.value }] }))} />
            break;
        case "select":
            editor =
                <Box borderStyle="solid" borderRadius={4} border={2} borderColor="primary.main" p={2}>
                    {(question.blocks || []).map((b, bIndex) =>
                        <SelectableTypography key={`block-${bIndex}`} className={b.type === "text" ? classes.questionText : classes.answer}
                            blockIndex={bIndex}
                            setSelectionMouseDownBlockIndex={setSelectionMouseDownBlockIndex}
                            onMouseDown={(e) => {
                                console.log("onMouseDown...");
                                console.log(document.getSelection().toString());
                                setSelectionMouseDownBlockIndex(bIndex);
                            }}
                            // onContextMenu={(e) => {
                            //     //e.preventDefault();
                            //     console.log(`onContextMenu @ block[${bIndex}][${document.getSelection().anchorOffset}][${document.getSelection().focusOffset}]`);
                            //     console.log(document.getSelection().toString());
                            //     console.log(question.blocks.map(b => `[${b.value}]`).join(""));
                            //     setSelectionMouseDownBlockIndex(bIndex);
                            // }}
                            onMouseUp={(e) => {
                                console.log("onMouseUp...");
                                console.log(document.getSelection().toString());
                                handleSelection(bIndex);
                            }}
                        // onFocus={(e) => {
                        //     console.log(`onFocus @ block[${bIndex}][${document.getSelection().anchorOffset}][${document.getSelection().focusOffset}]`);
                        //     console.log(document.getSelection().toString());
                        //     console.log(question.blocks.map(b => `[${b.value}]`).join(""));
                        //     setSelectionMouseDownBlockIndex(Math.min(...[bIndex, selectionMouseDownBlockIndex].filter(Number.isFinite)));
                        // }}
                        // onTouchStart={e => {
                        //     console.log("onTouchStart...");
                        //     console.log(document.getSelection().toString());
                        //     // e.preventDefault(); 
                        //     setSelectionMouseDownBlockIndex(bIndex);
                        // }}
                        // onTouchMove={e => {
                        //     console.log("onTouchEnd...");
                        //     console.log(document.getSelection().toString());
                        //     // e.preventDefault(); 
                        //     handleSelection(bIndex);
                        // }}
                        >{b.value}</SelectableTypography>
                    )}
                </Box >
            break;
        default:
            /**
             * Display depends on whether question has been generated
             */
            editor =
                Boolean(question?.blocks) ?
                    // Question already generated
                    question.blocks.map((b, bIndex) =>
                        <Typography key={`block - ${bIndex}`}
                            className={b.type === "text" ? classes.questionText : classes.answer}
                            onClick={isEditingSection ? () => { } : () => setStage("edit")}>{b.value}</Typography>)
                    :
                    // No question generated yet
                    <Box display="flex" onClick={isEditingSection ? () => { } : () => setStage("input")}>
                        <Typography className={classes.questionText}>{question.word}</Typography>
                        <Box ml={2} alignSelf="flex-end"><Typography className={classes.questionAiGenerationRemark}>AI will generate the question for you</Typography></Box>
                    </Box>
            break;
    }
    return (
        <React.Fragment>
            {Boolean(STAGES[stage]) ?
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="primary">{STAGES[stage]?.hint}</Typography>
                </Grid> : null}
            {/* Question Content Area */}
            <Grid item xs={12} className={classes.editorGrid}>
                <Typography className={classes.questionNumber}>{questionIndex + 1}.</Typography>
                <Box className={classes.editorBox}>{editor}</Box>
                {/* Show Generate button if not in any stage */}
                {!Boolean(question.stage) &&
                    <Tooltip title={Boolean(question.blocks) ? "Regenerate" : "Generate"}>
                        <IconButton className={classes.button}
                            color="primary"
                            size="small"
                            onClick={regenerateQuestion}>
                            <Icon>auto_fix_high</Icon>
                        </IconButton>
                    </Tooltip>}
                {/* End: Show Generate button if not in any stage */}
            </Grid>
            {/* End: Question Content Area */}
            {(Boolean(selectionMouseDownBlockIndex) && selectionMouseDownBlockIndex >= 0) &&
                /* Select Button when Context Menu is ON (For Android) */
                <Grid item xs={12} className={classes.buttonGrid}>
                    <Button className={classes.button} color="primary" onClick={contextMenuSelect}>Select</Button>
                </Grid>
                /* End: Select Button when Context Menu is ON (For Android) */
            }
            {Boolean(stage) &&
                <Grid item xs={12} className={classes.buttonGrid}>
                    <ButtonGroup>
                        {Object.keys(STAGES).map((stageKey, sIndex) =>
                            <Button key={sIndex}
                                className={classes.button} color="primary"
                                onClick={Boolean(stageKey === stage) ? null : () => setStage(stageKey)}
                                variant={Boolean(stageKey === stage) ? "contained" : null}
                                disabled={false}>{STAGES[stageKey]?.name}</Button>)}
                        {/* Next Question Button */}
                        <Button className={classes.button} color="primary" onClick={() => complete(true)} disabled={question?.blocks?.length === 0}>Next Question</Button>
                        {/* End: Next Question Button */}
                        {/* Complete Button */}
                        <Button className={classes.button} color="primary" onClick={() => complete(false)} disabled={question?.blocks?.filter(b => b.type === "answer").length === 0}>Complete</Button>
                        {/* End: Complete Button */}
                        {/* Button to remove question */}
                        {Boolean(STAGES[stage]) &&
                            <Button className={classes.button}
                                color="primary"
                                onClick={() => removeQuestion(questionIndex)}>Remove</Button>}
                        {/* End: Button to remove question */}
                    </ButtonGroup>
                    {/* End: Button Group for each stage */}
                </Grid>}
        </React.Fragment>
    );
}

const SelectableTypography = (props) => {
    const { className, blockIndex, setSelectionMouseDownBlockIndex, children, ...others } = props;
    const typographyRef = React.createRef();
    useEffect(() => {
        if (Boolean(typographyRef.current)) {
            if (!Boolean(typographyRef.current.onselectstart)) {
                typographyRef.current.onselectstart = (e) => {
                    console.log(`onselectstart @ block[${blockIndex}][${document.getSelection().anchorOffset}][${document.getSelection().focusOffset}]`);
                    console.log(document.getSelection().toString());
                    setSelectionMouseDownBlockIndex(blockIndex);
                }
            }
            if (!Boolean(typographyRef.current.onselectionchange)) {
                typographyRef.current.onselectionchange = (e) => {
                    console.log(`onselectionchange @ block[${blockIndex}][${document.getSelection().anchorOffset}][${document.getSelection().focusOffset}]`);
                    console.log(document.getSelection().toString());
                    setSelectionMouseDownBlockIndex(blockIndex);
                }
            }
            if (!Boolean(typographyRef.current.onselect)) {
                typographyRef.current.onselect = (e) => {
                    console.log(`onselect @ block[${blockIndex}][${document.getSelection().anchorOffset}][${document.getSelection().focusOffset}]`);
                    console.log(document.getSelection().toString());
                    setSelectionMouseDownBlockIndex(blockIndex);
                }
            }
        }

    }, [typographyRef.current])
    return (
        <Typography ref={typographyRef} blockIndex={blockIndex} className={className} {...others}>{children}</Typography>
    );
}

const AiGeneratedFillInBlankSectionEditorButtons = (props) => {
    const { section, generateQuestions } = props;
    const classes = makeStyles(theme => ({
        button: {
            fontWeight: 800
        }
    }))();
    return (
        <Box>
            <Button className={classes.button}
                color="primary"
                startIcon={<Icon>auto_awesome</Icon>}
                onClick={() => generateQuestions()}
                disabled={section?.questions?.filter(q => Boolean(q?.word) || Boolean(q?.blocks)).length === 0 || section?.questions?.some(q => Boolean(q?.stage))}>Regenerate all Questions</Button>
        </Box>
    );
}