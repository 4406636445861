import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * React Router
 */
import { useParams } from 'react-router-dom';

/**
 * Material UI
 */
import { Container, Grid, Typography, Box, Icon, IconButton, Button, AppBar, Tabs, Tab, TextField, InputAdornment, Tooltip, Toolbar } from '@material-ui/core';
import { makeStyles, createMuiTheme, ThemeProvider, useTheme } from '@material-ui/core/styles';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import MaskedInput from 'react-text-mask';

/**
 * QR Code
 */
import QRCode from 'qrcode.react';

/**
 * React Sppech Kit
 */
import { useSpeechSynthesis } from 'react-speech-kit';

/**
 * Firebase
 */
import { firestore, getFirestoreTimestamp } from '../../../common/serviecs/firebase';

/**
 * Application
 */
import { LoadingMask } from '../../../common/components/controls/LoadingMask';
import { MessageDialog } from '../../../common/components/controls/MessageDialog';
import { FillInBlankSection } from '../sections/FillInBlankSection';
import { MultipleChoiceSection } from '../sections/MultipleChoiceSection';
import { SentenceReorderingSection } from '../sections/SentenceReorderingSection';
import { QuestionAnswerSection } from '../sections/QuestionAnswerSection';
import { TrueOrFalseSection } from '../sections/TrueOrFalseSection';
import { ArithmeticOperationSection } from '../sections/ArithmeticOperationSection';
import { DictationSection } from '../sections/DictationSection';
import { formatDate, formatTimestamp } from '../../../common/functions/DateTimeUtils';
import { notifyUser } from '../../../common/components/controls/NotificationMenu';
import { MatchingSection } from '../sections/MatchingSection';
import { CustomSlider, DictionaryButton, DictionaryMask, TimeDisplay, Timer } from '../../../common/components/controls';
import { RichTextEditor } from '../../../lib/slate/RichTextEditor';
import { SectionTitle, SpeakButton, StopButton } from '../../../common/components/controls';
import { getChineseExplanation, isChinese, VOICE_LANGUAGES } from '../../../common/functions/LanguageUtils';
import { CUSTOM_THEME, LANGUAGE_CODE_CHINESE, LANGUAGE_CODE_CHINESE_FONT_STEP } from '../../Constants';
import { AiGeneratedFillInBlankSectionEditor } from '../section-editors/AiGeneratedFillInBlankSectionEditor';

/**
 * Constants
 */
const SECTION_RENDERERS = {
    "ai-generated-fill-in-blank": { key: "ai-generated-fill-in-blank", label: "Fill in Blank (AI Generated)", component: FillInBlankSection },
    "fill-in-blank": { key: "fill-in-blank", label: "Fill in Blank", component: FillInBlankSection },
    "multiple-choice": { key: "multiple-choice", label: "Multiple Choice", component: MultipleChoiceSection },
    "sentence-reordering": { key: "sentence-reordering", label: "Sentence Reordering", component: SentenceReorderingSection },
    "question-answer": { key: "question-answer", label: "Question & Answer", component: QuestionAnswerSection },
    "true-or-false": { key: "true-or-false", label: "True of False", component: TrueOrFalseSection },
    "arithmetic-operation": { key: "arithmetic-operation", label: "Arithmetic Operation", component: ArithmeticOperationSection },
    "matching": { key: "matching", label: "Matching", component: MatchingSection },
    "dictation": { key: "dictation", label: "Dictation", component: DictationSection },
    "times-table": { key: "times-table", label: "Times Table", component: null },
    "free-style-section": { key: "free-style-section", label: "Free Style Section", component: null },
};

const SECTION_KEY_PAPER_ATTRIBUTES = "paper-attributes";
const STATIC_TABS = [SECTION_KEY_PAPER_ATTRIBUTES];

const STAGE_CONFIG = {
    print: { attributeEditable: false, options: ["toggle-answer"] },
    "print-answer": { attributeEditable: false, options: ["toggle-answer"], showAnswer: true },
    select: { attributeEditable: true, options: ["start", "prepare"], hideQuestions: true },
    prepare: { attributeEditable: false, options: ["start"], showPreparationMaterial: true, hideQuestions: true },
    attempt: { attributeEditable: true, options: ["hand-in"], showTimer: true },
    marking: { attributeEditable: false },
    result: { attributeEditable: false, showScore: true, showAnswer: true }
}

/**
 * Reference for text select on mobile
 * https://stackoverflow.com/questions/65318217/event-listener-in-reactjs-for-mobile-browser
 */

export function Paper(props) {
    const { account, user, goto, isReview } = props;
    const { speak, cancel, speaking, supported, voices } = useSpeechSynthesis();
    const [selectedText, setSelectedText] = useState();
    const [selectedTextExplanation, setSelectedTextExplanation] = useState(false);
    const [explanations, setExplanations] = useState([]);
    const [speakRate, setSpeakRate] = useState(1);
    /**
     * No longer used
     * Reference - document.getSelection().getRangeAt(0).getClientRects()[0]
     */
    // const [selectionRects, setSelectionRects] = useState();

    const unsetSelection = () => {
        // Unset selection
        let selection = document.getSelection();
        if (Boolean(selection.removeAllRanges)) {
            selection.removeAllRanges();
        } else if (Boolean(selection.empty)) {
            selection.empty();
        }
    }

    const captureSelectedText = () => {
        let textSelected = document.getSelection().toString();
        if (Boolean(textSelected)) {
            setSelectedText(textSelected);
            if (isChinese(textSelected)) {
                // If the selection is Chinese characters, query corresponding dictionary explanation
                getChineseExplanation(textSelected).then(explanation => {
                    setSelectedTextExplanation(explanation);
                });
            }
        } else {
            setSelectedText(null);
            setSelectedTextExplanation(null);
        }
    }

    const addExplanation = (word, explanation) => {
        let modifiedExplanations = [...[], ...explanations];
        if (!explanations.map(e => e?.word).includes(word)) {
            // Append the explanation to the explanation array if not already in
            modifiedExplanations.push({ word: word, explanation: explanation });
            setExplanations(modifiedExplanations);
        }
    }

    const clearExplanations = () => {
        setSelectedText(null);
        setExplanations([...[]]);
        // Unset selection
        unsetSelection();
    }

    const removeExplanation = (word) => {
        setSelectedText(null);
        setExplanations(explanations.filter(e => e?.word !== word));
        // Unset selection
        unsetSelection();
    }

    const speakSelectedText = (text, languageCodes) => {
        let voice = voices.filter(v => languageCodes.includes(v.lang))[0];
        speak({ text: text, rate: speakRate, voice: voices.filter(v => languageCodes.includes(v.lang))[0] })
    }

    /**
     * Theme
     */
    const inherientedTheme = useTheme();
    const [fontSize, setFontSize] = useState(inherientedTheme.typography.fontSize);
    const contentTheme = createMuiTheme(
        Object.assign({}, inherientedTheme, CUSTOM_THEME,
            {
                typography: Object.assign({}, CUSTOM_THEME.typography, { fontSize: fontSize }),
                spacing: (Math.ceil(fontSize / inherientedTheme.typography.fontSize) * 8)
            }));
    // Use default font size from inheriented theme, yet allowing user to change font size manually
    const changeFontSize = (step, callback) => {
        setFontSize(fontSize + step, callback);
    }
    const classes = makeStyles(theme => ({
        root: {
        },
        flexBreak: {
            flexBasis: "100%",
            height: 0
        },
        button: {
            fontWeight: 800
        },
        title: {
            textAlign: "center",
            position: "relative",
            paddingBottom: theme.spacing(1),
            fontWeight: 800,
            "&::after": {
                position: "absolute",
                bottom: 0,
                left: "50%",
                height: 3,
                width: 200,
                borderBottom: "rgb(204,0,0) solid 3px",
                content: `''`,
                transform: "matrix(1,0,0,1,-100,0)"
            }
        },
        printButton: {
            position: "fixed",
            //top: theme.spacing(10),
            right: theme.spacing(2),
            zIndex: theme.zIndex.appBar
        },
        timerBox: {
            position: "fixed",
            marginTop: theme.spacing(0.5),
            left: theme.spacing(12.5),
            zIndex: theme.zIndex.appBar
        },
        timerText: {
            color: `${theme.palette.primary.main}80`,
            fontSize: "3em"
        },
        viewToggleGroup: {
            position: "fixed",
            //top: theme.spacing(10),
            left: theme.spacing(2),
            zIndex: theme.zIndex.appBar
        },
        sectionsAppBar: {
            position: "fixed",
            top: "auto",
            bottom: 0
        },
        sectionToolbar: {
            minHeight: 0
        },
        sectionBox: {
            width: "100%",
            marginTop: theme.spacing(3),
            marginBottom: theme.spacing(3),
        },
        toolbarHandInButton: {
            color: theme.palette.primary.contrastText,
            fontWeight: 800
        },
        footerLink: {
            textDecoration: "none",
        },
        dictionaryMaskContainer: {
            position: "fixed",
            marginLeft: theme.spacing(-3),
            zIndex: theme.zIndex.tooltip + 100,
        }
    }))();
    const [paper, setPaper] = useState();
    const [paperAttempt, setPaperAttempt] = useState();
    const [histories, setHistories] = useState();
    const [loading, setLoading] = useState(["Loading your test paper"]);
    const [message, setMessage] = useState(null);
    const [view, setView] = useState("print");
    const [workingSectionIndex, setWorkingSectionIndex] = useState(0);
    const { id, paperAttemptId, historyIndex = 0, currentStage } = useParams();
    const share = () => {
        if (navigator.share) {
            let headline = [[paper.grade, paper.subject].join(" - "), paper.title].join(" ");
            navigator.share({
                title: `${process.env.REACT_APP_APPLICATION_NAME}: ${headline}`,
                text: `${[headline, paper.subtitle].filter(t => Boolean(t)).join("\n")}\n`,
                url: `${process.env.REACT_APP_BASE_URL}/papers/${paper.id}`
            })
        }
    }
    const setDisplayView = (value) => {
        if (Boolean(value)) {
            setView(value);
        }
    }
    const addLoading = (loadingKey) => {
        setLoading([...loading, ...[loadingKey]]);
    }
    const removeLoading = (loadingKey) => {
        setLoading([...[], ...loading.filter(l => l !== loadingKey)]);
    }

    const updateSectionAttempt = (sectionIndex, sectionAttempt) => {
        let originalPaperAttempt = Object.assign({}, paperAttempt);
        originalPaperAttempt.sections[sectionIndex] = Object.assign({}, sectionAttempt);
        let includedSections = originalPaperAttempt.sections.filter(s => !Boolean(s?.configurations?.excluded));
        let numberOfAnswers = includedSections.filter(s => Boolean(s)).map(s => s.numberOfAnswers).reduce((sum, n) => sum + n, 0);
        let numberOfCorrectAnswers = includedSections.filter(s => Boolean(s)).map(s => s.score).reduce((sum, s) => sum + s, 0);
        let score = Math.round(paper.fullScore * numberOfCorrectAnswers / numberOfAnswers);
        setPaperAttempt(Object.assign({}, originalPaperAttempt, { score: score, numberOfAnswers: numberOfAnswers, numberOfCorrectAnswers: numberOfCorrectAnswers }));
    }

    const updatePaperAttribute = (key, value) => {
        setPaperAttempt(Object.assign({}, paperAttempt, { [key]: value }));
    }
    const updatePaperAttributes = (updatedAttributes) => {
        setPaperAttempt(Object.assign({}, paperAttempt, updatedAttributes));
    }

    const saveHistory = async (paperAttemptId, attempt) => {
        let _attempt = Object.assign({}, attempt, { id: null });
        await firestore.collection("test-papers").doc(id).collection("paper-attempts").doc(paperAttemptId).collection("histories").add(_attempt).then();
    }

    const savePaperAttempt = () => {
        // Save paper attempt as sub-collection
        addLoading("Submitting your test paper");
        let currentTimestamp = getFirestoreTimestamp();
        var adjustedPaperAttempt = Object.assign(paperAttempt, {
            school: paper.school || null,
            subject: paper.subject || null,
            title: paper.title || null,
            subtitle: paper.subtitle || null,
            grade: paper.grade || null,
            fullScore: paper.fullScore || null,
            createdAt: currentTimestamp,
            updatedAt: currentTimestamp,
            completedAt: currentTimestamp,
            attemptedBy: {
                ownerId: account.ownerUserId,
                id: user.id,
                fullName: user.fullName
            },
        });
        if (Boolean(paperAttemptId)) {
            // Ratake of the assignment
            firestore.collection("test-papers").doc(id).collection("paper-attempts").doc(paperAttemptId).set(adjustedPaperAttempt).then(async (docRef) => {
                if (adjustedPaperAttempt.assignedBy && adjustedPaperAttempt.assignedBy.id) {
                    await notifyUser(adjustedPaperAttempt.assignedBy, {
                        type: "info",
                        message: `${user.fullName} has completed your assignment with a score of ${adjustedPaperAttempt.score}/${adjustedPaperAttempt.fullScore}`,
                        path: `/papers/${id}/paper-attempts/${paperAttemptId}/review`,
                        createdAt: getFirestoreTimestamp()
                    });
                }
                await saveHistory(paperAttemptId, adjustedPaperAttempt);
                setPaperAttempt(adjustedPaperAttempt);
                removeLoading("Submitting your test paper");
                goto(`/papers/${id}/paper-attempts/${paperAttemptId}/review`);
            })
        } else {
            // First attempt of the assignment
            firestore.collection("test-papers").doc(id).collection("paper-attempts").add(adjustedPaperAttempt).then(async (docRef) => {
                let _paperAttemptId = docRef.id;
                await saveHistory(_paperAttemptId, adjustedPaperAttempt);
                setPaperAttempt(null);
                removeLoading("Submitting your test paper");
                goto(`/papers/${id}/paper-attempts/${docRef.id}/review`);
            })
        }
    }

    useEffect(() => {
        // Retrieve  the test paper from firestore
        firestore.collection("test-papers").doc(id).get().then(paperRef => {
            var retrievedPaper = Object.assign({ id: paperRef.id }, paperRef.data());
            if (Boolean(retrievedPaper)) {
                setPaper(retrievedPaper);
                var defaultPaperAttemptAttributes = {
                    paperId: id,
                    score: 0,
                    sections: retrievedPaper.sections.map(s => Object.assign({})),
                }
                // Set bigger font for Chinese language paper
                if (retrievedPaper?.languageCode === LANGUAGE_CODE_CHINESE) {
                    setFontSize(() => inherientedTheme.typography.fontSize + (3 * LANGUAGE_CODE_CHINESE_FONT_STEP));
                }
                // Retrieve the paper attempt if id provided
                if (Boolean(paperAttemptId)) {
                    firestore.collection(`/test-papers/${id}/paper-attempts/`).doc(paperAttemptId).get().then(paperAttemptRef => {
                        let retrievedPaperAttempt = Object.assign({ id: paperAttemptRef.id }, paperAttemptRef.data());
                        // Retrieve histories if it is in review mode
                        if (Boolean(isReview)) {
                            let _histories = [];
                            firestore.collection(`/test-papers/${id}/paper-attempts/${paperAttemptId}/histories`).orderBy("completedAt", "desc").get().then(historiesRef => {
                                historiesRef.forEach(history => {
                                    _histories.push(Object.assign({}, history.data(), { paperAttemptId: history.ref.parent.parent.id }))
                                });
                                setHistories(() => _histories);
                                if (_histories.length === 0) {
                                    setPaperAttempt(() => retrievedPaperAttempt);
                                } else {
                                    setPaperAttempt(() => _histories[historyIndex]);
                                }
                                removeLoading("Loading your test paper");
                            });
                        } else {
                            setPaperAttempt(
                                Object.assign({},
                                    defaultPaperAttemptAttributes,
                                    {
                                        assignedAt: retrievedPaperAttempt?.assignedAt,
                                        assignedBy: retrievedPaperAttempt?.assignedBy
                                    })
                            );
                            removeLoading("Loading your test paper");
                        }
                    });
                } else {
                    setPaperAttempt(defaultPaperAttemptAttributes);
                    removeLoading("Loading your test paper");
                }
            }
        });
    }, [id, paperAttemptId, isReview, historyIndex]);

    /**
     * Stage - Possible values:
     * 0. print (not yet implemented)
     * 1. select
     * 2. prepare (Read preparation material)
     * 3. attempt
     * 4. marking (not yet implemented)
     * 5. result
     */
    var stage = currentStage;
    if (!Boolean(stage) && Boolean(paper) && Boolean(paperAttempt)) {
        // 1. select - If there is time limit but attempt start time not set yet
        if (!Boolean(paperAttempt.attemptedAt)) {
            stage = "select";
        }
        // 2. prepare - If preparation start time is set 
        if (Boolean(paperAttempt.preparationStartedAt) && !Boolean(paperAttempt.preparationCompletedAt)) {
            stage = "prepare";
        }
        // 3. attempt - If attempt start time set but completed time not set yet
        if (Boolean(paperAttempt.attemptedAt) && !Boolean(paperAttempt.completedAt)) {
            stage = "attempt";
        }
        // 4. marking - If completed time set and require marking flag set
        if (Boolean(paperAttempt.completedAt) && Boolean(paperAttempt.requireMarking)) {
            stage = "marking";
        }
        // 5. result - If completed time set and not require marking flag set
        if (Boolean(paperAttempt.completedAt) && !Boolean(paperAttempt.requireMarking)) {
            stage = "result";
        }
    }
    /**
     * Stage Options Allowed
     */
    let stageOptions = STAGE_CONFIG[stage]?.options || [];
    if (!Boolean(paper?.preparationMaterial)) {
        stageOptions = stageOptions.filter(so => so !== "prepare");
    }

    const toggleShowAnswer = () => {
        let targetStageSuffix = Boolean(STAGE_CONFIG[stage].showAnswer) ? "" : "-answer";
        goto(`/papers/${id}/${stage}${targetStageSuffix}`);
    }

    /**
     * Debug
     */
    const [eventsTriggered, setEventsTriggered] = useState([]);
    const addEventTriggered = (newEvent) => {
        setEventsTriggered([...eventsTriggered, ...[newEvent]].slice(-20));
    }


    return (
        <React.Fragment>
            {Boolean(loading.length > 0) ? < LoadingMask message={`${loading.join(" / ")}...`} /> : null}
            {Boolean(message) ? <MessageDialog open={true} title="Test Paper Online" message={message} closeCallback={() => setMessage(null)} /> : null}

            {/* Control Buttons */}
            <Box displayPrint="none" className={classes.printButton} hidden={!Boolean(selectedText) && view === "tab"} mt={2}>
                {Boolean(selectedText) ?
                    <Box display="flex" flexDirection="row" flexWrap="wrap" justifyContent="center" alignItems="start">
                        {/* Dictionary Button */}
                        {Boolean(selectedTextExplanation) &&
                            <DictionaryButton onClick={() => addExplanation(selectedText, selectedTextExplanation)} />}
                        {/* End: Dictionary Button */}
                        {/* Speak/Stop Buttons */}
                        <React.Fragment>
                            {Boolean(speaking) ?
                                <StopButton onClick={cancel} /> :
                                <React.Fragment>
                                    {Object.values(VOICE_LANGUAGES).sort((l1, l2) => l1.order > l2.order).map(language =>
                                        <SpeakButton
                                            languageText={language.label}
                                            languageName={language.name}
                                            onClick={() => speakSelectedText(selectedText, language.codes)} />)}
                                    {/* Speak Rate Slider */}
                                    <SpeakRateSlider speakRate={speakRate} setSpeakRate={setSpeakRate} />
                                    {/* End: Speak Rate Slider */}
                                </React.Fragment>}
                        </React.Fragment>
                        {/* End: Speak/Stop Buttons */}
                    </Box> :
                    <Box display="flex" alignItems="start">
                        {/* Adjust Font Size Button */}
                        <FontSizeSlider defaultFontSize={inherientedTheme.typography.fontSize} fontSize={fontSize} setFontSize={setFontSize} />
                        {/* End: Adjust Font Size Button */}
                        {/* Share Button */}
                        {navigator.share &&
                            <Tooltip title="Share test paper">
                                <IconButton size="small" onClick={share}>
                                    <Icon>share</Icon>
                                </IconButton>
                            </Tooltip>}
                        {/* End: Share Button */}
                        {/* Print Button */}
                        <Tooltip title="Print">
                            <IconButton size="small" onClick={() => { window.print(); return false; }}>
                                <Icon>print</Icon>
                            </IconButton>
                        </Tooltip>
                        {/* End: Print Button */}
                    </Box>}
            </Box>
            {/* End: Control Buttons */}

            {Boolean(paper) && Boolean(paperAttempt) ?
                <React.Fragment>
                    <Container maxWidth="lg" key={paperAttempt.id}
                        // Generic event to capture selected text for speech (For Desktop)
                        onMouseUp={captureSelectedText}
                        // Generic event to capture selected text for speech (For Mobile)
                        onTouchEnd={captureSelectedText}    // Mac
                        onContextMenu={captureSelectedText} // Android
                    // onTouchMove={e => addEventTriggered("Touch Move")}
                    // onTouchStart={e => addEventTriggered("Touch Start")}
                    // onTouchEnd={e => addEventTriggered("Touch End")}
                    // onSelect={e => addEventTriggered("Select")}
                    // onContextMenu={e => addEventTriggered("Context Menu")}
                    // onPointerUp={e => addEventTriggered("Pointer Up")}
                    // onGotPointerCapture={e => addEventTriggered("Got Pointer Capture")}
                    // onLostPointerCapture={e => addEventTriggered("Lost Pointer Capture")}
                    // iPad - on Non-input
                    // Select:              Touch Start -> Got Pointer Capture -> Pointer Up -> Lost Pointer Capture -> Touch End
                    // Move after Select:   Touch Start -> Got Pointer Capture -> Touch Move... -> Pointer Up -> Lost Pointer Capture -> Touch End
                    // Touch Empty Area:    Same as select
                    //
                    // iPad - on Input
                    // Select:              Touch Start -> Select -> Pointer Up -> Touch End
                    // Move after Select:   Touch Start -> Got Pointer Capture -> Touch Move... Select -> Touch Move... -> Pointer Up -> Lost Pointer Capture -> Touch End
                    // Focus:               Touch Start -> Pointer Up -> Touch End
                    // Touch Empty Area:    Same as select
                    //
                    // iPad - on Disabled Input
                    // Select:              Touch Start -> Pointer Up -> Touch End
                    // Move after Select:   Touch Start -> Got Pointer Capture -> Touch Move... -> Pointer Up -> Lost Pointer Capture -> Touch End
                    // Touch Empty Area:    Same as select
                    //
                    // Android on Non-input
                    // Select:              Touch Start -> Got Pointer Capture -> Context Menu -> Pointer Up -> Lost Pointer Capture -> Touch End
                    // Move after Select:   Context Menu
                    // Touch Empty Area:    Touch Start -> Got Pointer Capture -> Pointer Up -> Lost Pointer Capture -> Touch End
                    //
                    // Android on Input
                    // Select:              Touch Start -> Got Pointer Capture -> Select -> Lost Pointer Capture -> Touch End
                    // Move after Select:   Select...
                    // Focus:               Select...
                    // Touch Empty Area:    Touch Start -> Got Pointer Capture -> Pointer Up -> Lost Pointer Capture -> Touch End
                    //
                    // Android on Disabled Input (Same as Non-input)
                    // Select:              Touch Start -> Got Pointer Capture -> Context Menu -> Pointer Up -> Lost Pointer Capture -> Touch End
                    // Move after Select:   Context Menu
                    // Touch Empty Area:    Touch Start -> Got Pointer Capture -> Pointer Up -> Lost Pointer Capture -> Touch End
                    >

                        {/* View - Print/Tab */}
                        <Box displayPrint="none" className={classes.viewToggleGroup} mt={2}>
                            <ToggleButtonGroup size="small" value={view} exclusive onChange={(e, newValue) => setDisplayView(newValue)} >
                                <ToggleButton key={"print"} value={"print"}>
                                    <Tooltip title="Print layout">
                                        <Icon>print</Icon>
                                    </Tooltip>
                                </ToggleButton>
                                <ToggleButton key={"tab"} value={"tab"}>
                                    <Tooltip title="Tab layout">
                                        <Icon>reorder</Icon>
                                    </Tooltip>
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        {/* End: View - Print/Tab */}
                        {/* Timer */}
                        {!Boolean(paperAttemptId) && paper.timeLimitInMinutes && Boolean(paperAttempt.attemptedAt) &&
                            <Box displayPrint="none" className={classes.timerBox}>
                                <Timer timeLimitInSeconds={paper.timeLimitInMinutes * 60} textClassName={classes.timerText} />
                            </Box>}
                        {/* End: Timer */}
                        {/* Content Area - Apply Content Theme: Mainly for resetting Font Size */}
                        <ThemeProvider theme={contentTheme}>

                            {/* Print Left Border */}
                            <div className="page-border"></div>
                            {/* End: Print Left Border */}

                            {/* Print Watermark */}
                            {Boolean(user) && <div className="watermark">{process.env.REACT_APP_BASE_URL}</div>}
                            {/* End: Print Watermark */}

                            {/* Use Table Layout for Print */}
                            <table class="print-table">
                                {/* Print Header Container */}
                                <thead class="print-table-header-group">
                                    <tr class="print-table-row">
                                        <td class="print-table-header-cell">
                                            <div style={{ height: "100px", width: "100%" }}>&nbsp;</div>
                                        </td>
                                    </tr>
                                </thead>
                                {/* End: Print Header */}

                                {/* Print Content */}
                                <tbody class="print-table-row-group">
                                    <tr class="print-table-row">
                                        <td class="print-table-cell">

                                            {/* Headline */}
                                            <Grid container spacing={3}>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.title} variant="h5">{paper.grade} {paper.subject} - {paper.title}</Typography>
                                                    <Box textAlign="center" mt={0.5} mb={2}>
                                                        <Typography variant="subtitle1" style={{ fontWeight: 800 }}>{paper.subtitle}</Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                            {/* End: Headline */}

                                            {/* Dictionary Mask */}
                                            {Boolean(explanations) &&
                                                <Container maxWidth="lg" className={classes.dictionaryMaskContainer}>
                                                    <Grid container spacing={0}>
                                                        <Grid item xs={12}>
                                                            <DictionaryMask
                                                                explanations={explanations}
                                                                clearExplanations={clearExplanations}
                                                                removeExplanation={removeExplanation} />
                                                        </Grid>
                                                    </Grid>
                                                </Container>}
                                            {/* End: Dictionary Mask */}

                                            {/* Cover Page */}
                                            {view !== "tab" || workingSectionIndex === 0 ?
                                                <PaperAttributes
                                                    goto={goto}
                                                    key="paper-attribute"
                                                    editable={Boolean(STAGE_CONFIG[stage].attributeEditable)}
                                                    showScore={Boolean(STAGE_CONFIG[stage].showScore)}
                                                    user={user} paper={paper}
                                                    paperAttempt={paperAttempt}
                                                    paperAttemptId={paperAttemptId}
                                                    view={view} updatePaperAttribute={updatePaperAttribute}
                                                    histories={histories}
                                                    historyIndex={historyIndex} />
                                                : null}
                                            {/* End: Cover Page */}

                                            <Grid container>
                                                <Grid item xs={12}>
                                                    <Box my={5} />
                                                </Grid>
                                                {/* Prepare Button */}
                                                {stageOptions.includes("prepare") &&
                                                    <Grid item xs={12} md={12 / stageOptions.length}>
                                                        <Box display="flex" flexDirection="column" alignItems="center" my={1}>
                                                            <Box>
                                                                <Button color="primary" variant="outlined" onClick={() => updatePaperAttribute("preparationStartedAt", getFirestoreTimestamp())}>Prepare</Button>
                                                            </Box>
                                                            <Box my={2}>
                                                                <Typography>This paper contains preparation materials.</Typography>
                                                                <Typography>You can read it throught before taking the test.</Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>}
                                                {/* End: Prepare Button */}

                                                {/* Start Button */}
                                                {stageOptions.includes("start") &&
                                                    <Grid item xs={12} md={12 / stageOptions.length}>
                                                        <Box display="flex" flexDirection="column" alignItems="center" my={1}>
                                                            <Box>
                                                                <Button color="primary" variant="outlined"
                                                                    onClick={() => updatePaperAttributes({
                                                                        preparationCompletedAt: getFirestoreTimestamp(),
                                                                        attemptedAt: getFirestoreTimestamp()
                                                                    })}>Start</Button>
                                                            </Box>
                                                            <Box>
                                                                <ol>
                                                                    <Typography component="li">You are about to start the test.</Typography>
                                                                    {Boolean(paper?.timeLimitInMinutes) ?
                                                                        <React.Fragment>
                                                                            <Typography component="li">You will have <u><strong>{paper.timeLimitInMinutes} minutes</strong></u> to finish the test.</Typography>
                                                                            <Typography component="li">You cannot pause the timer once it is started.</Typography>
                                                                        </React.Fragment> :
                                                                        <Typography component="li">There is no time limit for this test.</Typography>}
                                                                </ol>
                                                            </Box>
                                                        </Box>
                                                    </Grid>}
                                                {/* End: Start Button */}
                                            </Grid>

                                            {!Boolean(STAGE_CONFIG[stage].hideQuestions) &&
                                                <React.Fragment>
                                                    {/* Sections */}
                                                    {paper.sections.map((section, sectionIndex) => {
                                                        let sectionAttempt = paperAttempt.sections[sectionIndex];
                                                        return (
                                                            !Boolean(sectionAttempt?.configurations?.excluded) ?
                                                                <Box className={classes.sectionBox}
                                                                    key={section.id}
                                                                    hidden={view === "tab" && (workingSectionIndex - STATIC_TABS.length) !== sectionIndex}>
                                                                    {React.createElement(
                                                                        SECTION_RENDERERS[section.type].component,
                                                                        {
                                                                            key: section.id,
                                                                            sectionIndex: sectionIndex,
                                                                            section: section,
                                                                            sectionAttempt: paperAttempt.sections[sectionIndex],
                                                                            updateSectionAttempt: updateSectionAttempt,
                                                                            showScore: Boolean(STAGE_CONFIG[stage].showScore),
                                                                            showAnswer: Boolean(STAGE_CONFIG[stage].showAnswer)
                                                                        })}
                                                                </Box> :
                                                                null
                                                        );
                                                    })}
                                                    {/* End: Sections */}

                                                    {/* Hand In Button (Print View) */}
                                                    {!Boolean(paperAttempt.completedAt) && Boolean((STAGE_CONFIG[stage].options || []).includes("hand-in")) &&
                                                        <Box displayPrint="none" hidden={view !== "print"}>
                                                            <Grid item xs={12} style={{ textAlign: "right" }}>
                                                                <Button className={classes.button} color="primary" variant="outlined" onClick={savePaperAttempt}>Hand In</Button>
                                                            </Grid>
                                                        </Box>}
                                                    {/* End: Hand In Button (Print View) */}

                                                    {/* Show Answer Button (Print View) */}
                                                    {Boolean((STAGE_CONFIG[stage].options || []).includes("toggle-answer")) &&
                                                        <Box displayPrint="none" hidden={view !== "print"}>
                                                            <Grid item xs={12} style={{ textAlign: "right" }}>
                                                                <Button className={classes.button} color="primary" variant="outlined" onClick={toggleShowAnswer}>{STAGE_CONFIG[stage].showAnswer ? "Hide" : "Show"} Answer</Button>
                                                            </Grid>
                                                        </Box>}
                                                    {/* End: Show Answer Button (Print View) */}

                                                </React.Fragment>
                                            }
                                            <Box mb={10} />
                                        </td>
                                    </tr>
                                </tbody>
                                {/* End: Print Content */}

                                {/* Print Footer Container */}
                                <tfoot class="print-table-footer-group">
                                    <tr class="print-table-row">
                                        <td class="print-table-footer-cell">
                                            <div style={{ height: "100px", width: "100%" }}>&nbsp;</div>
                                        </td>
                                    </tr>
                                </tfoot>
                                {/* End: Print Footer */}
                            </table>
                            {/* End: Use Table Layout for Print */}

                            {/* Actual Header */}
                            <div className="page-header-left">
                                <img alt="Test Paper Online" src="/logo192.png" height="80" width="80" />
                            </div>
                            <div className="page-header-right">
                                <QRCode style={{ verticalAlign: "top", display: "inline-block" }}
                                    value={`${process.env.REACT_APP_BASE_URL}/papers/${id}`} size={120} />
                            </div>
                            {/* End: Actual Header */}
                            {/* Actual Footer */}
                            <div className="page-footer-left">
                                <Typography variant="subtitle2" color="primary" style={{ fontSize: "18px" }}>
                                    {user.fullName} ({user.email || user.ownerId})
                                </Typography>
                            </div>
                            <div className="page-footer-right">
                                <a href={process.env.REACT_APP_BASE_URL} target="_blank" rel="noopener noreferrer" className={classes.footerLink}>
                                    <Typography variant="subtitle2" color="primary" style={{ fontSize: "18px" }}>
                                        {process.env.REACT_APP_BASE_URL}
                                    </Typography>
                                </a>
                            </div>
                            <div id="page-footer-page-number"></div>
                            {/* End: Actual Footer */}

                        </ThemeProvider>
                        {/* End: Content Area - Apply Content Theme: Mainly for resetting Font Size */}
                    </Container>
                    {view === "tab" ?
                        <AppBar className={classes.sectionsAppBar}>
                            <Toolbar className={classes.sectionToolbar}>
                                <Tabs value={workingSectionIndex} onChange={(e, newValue) => setWorkingSectionIndex(newValue)} variant="scrollable" scrollButtons="auto" style={{ flexGrow: 1 }}>
                                    <Tab key={SECTION_KEY_PAPER_ATTRIBUTES} label="Your Test Paper" wrapped />
                                    {paper.sections.map((section, sectionIndex) => {
                                        // Hide excluded sections
                                        return (
                                            !Boolean(paperAttempt?.sections[sectionIndex]?.configurations?.excluded) ?
                                                <Tab key={section.id} label={section.title} wrapped /> :
                                                null
                                        );
                                    })}
                                </Tabs>
                                {Boolean(paperAttempt.completedAt) ? null : <Button className={classes.toolbarHandInButton} onClick={savePaperAttempt} >Hand In</Button>}
                            </Toolbar>
                        </AppBar> : null}
                </React.Fragment> : null
            }
        </React.Fragment >
    );
}

export function PaperAttributes(props) {
    const { goto, user, paper, paperAttempt, paperAttemptId, updatePaperAttribute, editable, showScore, histories, historyIndex } = props;
    const timeTakenInSeconds = Boolean(paperAttempt) && Boolean(paperAttempt.attemptedAt) && Boolean(paperAttempt.completedAt) ? paperAttempt.completedAt - paperAttempt.attemptedAt : null;
    const fullScoreNumberOfDigits = paper.fullScore.toString().length; // Math.floor(Math.log10(paper.fullScore)) + 1;
    const score = showScore ? paperAttempt.score : "";
    const scoreNumberOfDigits = score.toString().length;
    const classes = makeStyles(theme => ({
        historiesTable: {
            color: theme.palette.grey[500],
            textAlign: "right",
            cursor: "pointer"
        },
        historiesRowActive: {
            color: theme.palette.primary.light,
        },
        historiesTableScoreCell: {
            borderColor: theme.palette.grey[500],
            fontSize: "1.2rem",
            fontWeight: 800
        },
        historiesTableComplatedAtCell: {
            paddingLeft: theme.spacing(2),
            borderColor: theme.palette.grey[500],
            fontSize: "0.7rem",
            fontWeight: 600
        }
    }))();

    return (
        <Grid container spacing={1}>
            {/* Time Allowed & Time Taken */}
            <Grid item xs={12} sm={6}>
                {Boolean(paper.timeLimitInMinutes) &&
                    <Typography variant="subtitle2">Time Allowed: {paper.timeLimitInMinutes} Minutes</Typography>}
                {timeTakenInSeconds &&
                    <React.Fragment>
                        <Typography variant="subtitle2" component="span">Time Taken: </Typography>
                        <Typography variant="subtitle2" component="span">
                            <TimeDisplay timeInSeconds={timeTakenInSeconds} />
                        </Typography>
                    </React.Fragment>}
            </Grid>
            {/* End: Time Allowed & Time Taken */}
            <Grid item xs={12} sm={6}>
                <TextField label="Score"
                    variant="outlined"
                    value={`${score} / ${paper.fullScore}`}
                    disabled={true}
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={
                        Boolean(paperAttempt?.completedAt) ?
                            { endAdornment: <InputAdornment><Typography style={{ fontSize: "0.8rem" }}>{formatTimestamp(paperAttempt?.completedAt.toDate()) || "-"}</Typography></InputAdornment> } :
                            null
                    }
                    inputProps={{ style: { textAlign: "center", fontSize: "1.5em", paddingLeftss: `${fullScoreNumberOfDigits - scoreNumberOfDigits}em` } }} />
                {/* Show histories only if there is more than one */}
                {Boolean((histories || []).length > 1) &&
                    <Box display="flex" justifyContent="flex-end" mt={1}>
                        <table className={classes.historiesTable}>
                            {(histories || []).map((history, hIndex) =>
                                <tr key={hIndex}
                                    className={(hIndex == historyIndex) ? classes.historiesRowActive : null}
                                    onClick={() => goto(`/papers/${paper.id}/paper-attempts/${paperAttemptId}/review/${hIndex}`)}>
                                    <td>
                                        <Typography className={classes.historiesTableScoreCell}>{history?.score} / {history.fullScore}</Typography>
                                    </td>
                                    <td>
                                        <Typography className={classes.historiesTableComplatedAtCell}>{formatTimestamp(history?.completedAt.toDate()) || "-"}</Typography>
                                    </td>
                                </tr>
                            )}
                        </table>
                    </Box>}
                {/* End: Show histories only if there is more than one */}
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField label="Name"
                    value={Boolean(paperAttempt.attemptedBy) ? paperAttempt.attemptedBy.fullName : user.fullName}
                    disabled={true}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField label="Date"
                    value={formatDate(new Date())}
                    disabled={true}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField label="School"
                    value={paperAttempt.school || paper.school}
                    onChange={e => updatePaperAttribute("school", e.target.value)}
                    disabled={!editable}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={6} sm={4}>
                <TextField label="Class"
                    value={paperAttempt.class || user.class}
                    onChange={e => updatePaperAttribute("class", e.target.value)}
                    disabled={!editable}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            <Grid item xs={6} sm={2}>
                <TextField label="No."
                    type="number"
                    inputProps={{ style: { textAlign: "center" } }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start" > (</InputAdornment>,
                        endAdornment: <InputAdornment position="end">)</InputAdornment>
                    }}
                    value={paperAttempt.classNumber || user.classNumber}
                    onChange={e => updatePaperAttribute("classNumber", e.target.value)}
                    disabled={!editable}
                    fullWidth InputLabelProps={{ shrink: true }} />
            </Grid>
            {/* Introduction */}
            {
                Boolean(paper.richText) && (!Boolean(paperAttempt.preparationStartedAt) || Boolean(paperAttempt.preparationCompletedAt)) &&
                <Grid item xs={12}>
                    <Box mt={3}>
                        <SectionTitle title="Introduction" />
                        <Typography variant="body1">
                            <Box p={2} border={1} borderRadius={4} borderColor="textPrimary">
                                <RichTextEditor readOnly={true} value={paper.richText} />
                            </Box>
                        </Typography>
                    </Box>
                </Grid>
            }
            {/* Introduction */}
            {/* Preparation Materials */}
            {
                Boolean(paper.preparationMaterial) && Boolean(paperAttempt.preparationStartedAt) && !Boolean(paperAttempt.preparationCompletedAt) &&
                <Grid item xs={12}>
                    <Box mt={3}>
                        <Typography variant="body1">
                            <SectionTitle title="Preparation Materials" />
                            <Box p={2} border={1} borderRadius={4} borderColor="textPrimary">
                                <RichTextEditor readOnly={true} value={paper.preparationMaterial} />
                            </Box>
                        </Typography>
                    </Box>
                </Grid>
            }
            {/* End:  Preparation Materials */}
        </Grid >
    );
}

function ClassTextMask(props) {
    const { inputRef, ...other } = props;

    return (
        <MaskedInput
            style={{ textAlign: "right" }}
            {...other}
            ref={(ref) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={['(', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ')']}
            placeholderChar={'\u2000'}
            showMask
        />
    );
}

ClassTextMask.propTypes = {
    inputRef: PropTypes.func.isRequired,
};

function FontSizeSlider(props) {
    const classes = makeStyles(theme => ({
        slider: {
            color: theme.palette.text.secondary,
            width: theme.spacing(15),
            marginRight: theme.spacing(1)
        }
    }))();
    const { fontSize, setFontSize, defaultFontSize } = props;
    const [isShowSlider, setShowSlider] = useState(false);
    return (
        <Box component="span" display="flex" alignItems="start">
            {Boolean(isShowSlider) &&
                <CustomSlider className={classes.slider} gutterTop value={((fontSize - defaultFontSize) / 3) + 1} min={1} max={10}
                    valueLabelDisplay="on" marks
                    onChange={(e, newValue) => setFontSize(defaultFontSize + ((newValue - 1) * 3))}
                    onChangeCommitted={e => setShowSlider(false)} />}
            <Tooltip title="Adjust text size">
                <IconButton onClick={e => setShowSlider(!isShowSlider)} size="small">
                    <Icon>format_size</Icon>
                </IconButton>
            </Tooltip>
        </Box>
    );
}

const SpeakRateSlider = (props) => {
    const classes = makeStyles(theme => ({
        sliderBox: {
            height: theme.spacing(25)
        },
        slider: {
            color: theme.palette.text.primary,
        }
    }))();
    const { speakRate, setSpeakRate } = props;
    const [isShowSlider, setShowSlider] = useState(false);
    return (
        <Box component="span" display="flex" flexDirection="column" alignItems="center" className={classes.sliderBox}>
            <Tooltip title="Adjust speak rate">
                <IconButton onClick={e => setShowSlider(!isShowSlider)} size="small">
                    <Icon>speed</Icon>
                </IconButton>
            </Tooltip>
            {Boolean(isShowSlider) &&
                <React.Fragment>
                    <Box textAlign="center">
                        <Typography variant='caption'>x{speakRate}</Typography>
                    </Box>
                    <CustomSlider orientation='vertical'
                        getAriaValueText={v => `x${v}`}
                        size="large"
                        className={classes.slider} gutterTop
                        value={speakRate}
                        min={0.5} max={2} step={0.25}
                        marks={true}
                        onChange={(e, newValue) => setSpeakRate(newValue)}
                        onChangeCommitted={e => setShowSlider(false)} />
                </React.Fragment>}
        </Box>
    );
}


